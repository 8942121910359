@use 'styles/configs/' as *;

.toys-machine-window {
    width: 100%;
    height: 100%;
    &__claw-wrapper {
        position: absolute;
        left: 0;
        top: 0;
        width: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 40%;
        transition: all 1.2s ease-in-out;
        &.--step-5 {
            left: 74%;
        }
        &.--step-4 {
            left: 50%;
        }
        &.--step-3 {
            left: 32%;
        }
        &.--step-2 {
            left: 14%;
        }
        &.--step-1 {
            left: -5%;
        }
        &.--is-dropped {
            height: 80%;
        }
        &.--is-grabbing {
            transition: all 0.25s ease-in;
        }
    }
    &__claw-open,
    &__claw-close {
        width: 100%;
        display: none;
        &.d-block {
            display: block;
        }
    }
    &__claw-rope {
        flex-grow: 1;
        background-color: black;
        height: 100%;
        min-height: 20px;
        width: 6px;

        @include media-breakpoint-down(md) {
            min-height: 20px;
            width: 6px;
        }
    }
    &__claw {
        width: 120px;
    }
    &__toys-wrapper {
        width: 72%;
        height: 100%;
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        align-items: flex-end;
        gap: vw(4);
        position: absolute;
        bottom: 10%;
        left: 2%;

        @include media-breakpoint-down(md) {
            gap: vw(4, true);
        }
    }
    &__toy-element {
        display: flex;
    }
    &__toys {
        width: 100%;
        @include transit();
        .--is-choosen & {
            opacity: 0;
        }
    }
    &__toys-claw {
        width: 26%;
        position: absolute;
        bottom: 0;
        transition: bottom 0.25s ease-in 0.75s, left 0.26s ease-in 1.5s;
        left: 0;
        &.--toy-2 {
            width: 24%;
            left: 25%;
        }
        &.--toy-3 {
            width: 26%;
            left: 51%;
        }
        &.--toy-4 {
            width: 24%;
            left: 76%;
            transition: bottom 0.25s ease-in 0.75s, left 0.3s ease-in 1.5s;
        }
        .--is-choosen & {
            bottom: 40%;
            left: 108%;
        }
        .--is-dropped & {
            transition: all 0.25s ease-in 1s;
            bottom: 0;
        }
    }
}
