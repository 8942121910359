@use 'styles/configs/' as *;

.activity-mirror-animation {
  height: 100%;
  display: block;
  &__open-door-screen {
    position: absolute;
    object-fit: cover;
    height: 100%;
    min-width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
