@use 'sass:math';

$base_f_size: 16;

$f_main: 'Changa', sans-serif;
$f_secondary: 'Changa One', cursive;
$f_inter: 'Inter', sans-serif;
$f_cute: 'Cute Font', cursive;

$f_list: (
  'main': $f_main,
  'secondary': $f_secondary,
  'inter': $f_inter,
);

$f_weights: (
  'regular': 400,
  'medium': 500,
  'extra-bold': 800,
);

//Key = size | Value = line height
$f_sizes: (
  12: 16,
  14: 22,
  15: 24,
  16: 26,
  18: 18,
  22: 40,
  24: 40,
  30: 40,
  32: 40,
);

/* COLORS */
$c_primary: #509fae;
$c_secondary: #21132c;

$c_white: #ffffff;
$c_black: #000000;
$c_blackOpacity: #000000D9;
$c_light-black: #11123b;

$c_background-top: #4b2167;
$c_background-bottom: #170e21;

$c_error: #ff5006;
$c_warning: #f5e800;
$c_warning_hover: #e9dc00;
$c_success: #3affc1;

$c_list: (
  'primary': $c_primary,
  'secondary': $c_secondary,
  'white': $c_white,
  'black': $c_black,
  'blackOpacity': $c_blackOpacity,
  'lightBlack': $c_light-black,
  'background': linear-gradient(180deg, $c_background-top 0%, $c_background-bottom 100%),
  'error': $c_error,
  'warning': $c_warning,
  'warningHover': $c_warning_hover,
  'success': $c_success,
);

$zindex: (
  barba: 99999,
  modal: 9999,
  overlay: 9000,
  header: 100,
  util: 75,
  above-content: 10,
);

/* Utilities */
$image_ratio_list: (
  '110': 110%,
  '100': 100%,
  '90': 90%,
  '80': 80%,
  '70': 70%,
  '45': 45%,
  '16-9': math.div(100%, 16) * 9,
  '4-3': math.div(100%, 4) * 3,
);

/* A11y */
$a11y_sizes: (
  100: $base_f_size,
  150: $base_f_size * 1.25,
  200: $base_f_size * 1.5,
);

/* VW variables */
$based-container-width-for-font-size: 1440;
$mobile-based-container-width-for-font-size: 375;

