@use 'styles/configs/' as *;

.screen-contest-ending {
  height: 100%;

  &__buttons {
    display: flex;
    flex-direction: column;
    gap: vw(16, true);
    margin-top: vw(24, true);

    @include media-breakpoint-up(md) {
      gap: vw(16);
      margin-top: vw(24);
    }

    @include media-breakpoint-up(lg) {
      margin-top: vw(40);
      gap: vw(20);
      flex-direction: row;
    }
  }

  &__buttons {
    z-index: 1;
    position: absolute;
    bottom: vw(25, true);
    left: vw(20, true);
    right: vw(20, true);

    display: flex;
    flex-direction: column;
    gap: vw(8, true);

    button {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: vw(16);
      bottom: vw(25);
      right: unset;
      left: 50%;
      transform: translate(-50%, 0);

      button {
        width: unset;
      }
    }
  }
}