@use 'styles/configs/' as *;

.button {
  width: max-content;
  &.--is-styled {
    color: map-get($map: $c_list, $key: 'black');
    font-family: $f_main;
    @include text(18, 25);
    font-weight: map-get($map: $f_weights, $key: 'extra-bold');
    text-align: center;
    padding: 12.5px 30px;
    background-color: map-get($map: $c_list, $key: 'warning');
    border: none;
    outline: none;
    box-shadow: 0 0 8px 0 map-get($map: $c_list, $key: 'warning');
    cursor: pointer;
    transition: all 0.15s ease-in;
    text-decoration: none;
    border-radius: vw(50);

    @include media-breakpoint-down(md) {
      padding: 12.5px 30px;
      border-radius: 50px
    }

    &:hover,
    &:focus,
    &:active {
      background-color: map-get($map: $c_list, $key: 'warningHover');
      box-shadow: 0 0 12px 0 map-get($map: $c_list, $key: 'warning');
    }
    &.--medium {
      padding: 12.5px 30px;
      @include text(18, 25);

      @include media-breakpoint-down(md) {
        padding: 12.5px 30px;
      }
    }
    &.--big {
      padding: 12.5px 30px;
      @include text(18, 25);

      @include media-breakpoint-down(md) {
        padding: 12.5px 30px;
      }
    }

    &.rounded{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 50px;
      width: 50px;
      line-height: 0;
      padding: 0;

      @include media-breakpoint-down(md) {
        height: 50px;
        width: 50px;
      }

      .navigation-arrow-next {
        transform: rotate(180deg);
      }

      .svg {
        transition: all 0.15s ease-in;
        pointer-events: none;
      }
    }

    .check-icon{
      display: inline-block;
      position: relative;
      width: 20px;
      height: 20px;
      background: white;
      border-radius: 12px;
      margin-left: 10px;
      vertical-align: sub;

      .svg{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  &.--is-disabled {
    opacity: 0.6;
    cursor: not-allowed;
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 8px map-get($map: $c_list, $key: 'warning');
    }

    &.rounded {
      background: transparent;
      box-shadow: none;
      opacity: 0.3;
      filter: invert(100%);
    }
  }
  &.--background-red {
    color: map-get($map: $c_list, $key: 'white');
    background-color: map-get($map: $c_list, $key: 'error');
    box-shadow: 0 0 8px 0 map-get($map: $c_list, $key: 'error');
    &:hover,
    &:focus,
    &:active {
      box-shadow: 0 0 12px 0 map-get($map: $c_list, $key: 'error');
    }
    &.--is-disabled {
      &:hover,
      &:focus,
      &:active {
        box-shadow: 0 0 8px 0 map-get($map: $c_list, $key: 'error');
      }
    }
  }
  &.--outline {
    color: map-get($map: $c_list, $key: 'white');
    background-color: transparent;
    box-shadow: 0 0 8px 0 map-get($map: $c_list, $key: 'white');
    outline: 2px solid map-get($map: $c_list, $key: 'white');
    transition: box-shadow 0.15s ease-in, background-color 0.20s ease-in, color 0.20s ease-in;
    &:hover,
    &:focus,
    &:active {
      color: map-get($map: $c_list, $key: 'black');
      background-color: rgba($color: map-get($map: $c_list, $key: 'white'), $alpha: 1);
      box-shadow: 0 0 12px 0 map-get($map: $c_list, $key: 'white');
    }

    &.rounded{
      &:hover,
      &:focus,
      &:active {
        .svg {
          filter: invert(100%);
        }
      }
    }
  }
  .--icon-has-label {
    padding-left: vw(12);

    @include media-breakpoint-down(md) {
      padding-left: vw(12, true);
    }
  }
}
