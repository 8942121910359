@use 'styles/configs/' as *;

.activity-vape-animation {
  height: 100%;
  display: block;
  position: relative;

  &.--is-animation {
    background: linear-gradient(0deg, rgba(23,14,33,1) 0%, rgba(23,14,33,1) 34%, rgba(74,32,102,1) 35%); 
  }

  &__table-scene {
    position: absolute;
    object-fit: cover;
    min-width: 100%;
    transform: translate(-50%, 0);
    bottom: vw(120, true);
    left: 50%;

    @include media-breakpoint-up(md) {
      bottom: vw(40);
    }
  }

  &__table-animation {
    width: 100%;
    height: auto;
    display: block;
    width: 160%;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);

    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
}
