@use 'styles/configs/' as *;

.screen-end {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;

  &__top {
    width: 100%;
    overflow: hidden;
    flex-grow: 1;
  }

  &__bottom {
    @include text(18, 20, 'extra-bold', null, 'main');
    color: map-get($map: $c_list, $key: 'white');
    background-size: auto 100%;
    background-repeat: repeat-x;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: vw(10, true);
    padding: vw(10, true) vw(20, true);
    box-sizing: border-box;
    @include media-breakpoint-up(md) {
      @include text(24, 29, 'extra-bold', null, 'main');
      flex-direction: row;
      margin-top: vw(10);
      padding: vw(40) vw(20) vw(60) vw(20);
      gap: vw(40);
    }
    @include media-breakpoint-up(lg) {
      padding: vw(60) vw(20) vw(80) vw(20);
      gap: vw(60);
    }
  }

  &__content-description {
    display: flex;
    align-items: center;
    gap: vw(20, true);
    flex-direction: column;
    width: 80%;
    text-align: center;
    max-width: vw(540);
    @include media-breakpoint-up(md) {
      width: auto;
      gap: vw(20);
      align-items: flex-start;
      text-align: left;
    }
  }

  &__ticket {
    width: 30%;
    max-width: vw(200, true);
    @include media-breakpoint-up(md) {
      max-width: vw(200);
    }
  }

  &__retry-button {
    cursor: pointer;
    background: transparent;
    border: none;
    align-items: center;
    gap: vw(12, true);
    padding-left: 0;
    display: flex;
    color: map-get($map: $c_list, $key: 'white');
    @include text(12, 12);
    font-family: $f_main;
    font-weight: map-get($map: $f_weights, $key: 'regular');
    transition: all 0.25s ease-in;
    @include media-breakpoint-up(md) {
      gap: vw(12);
    }
    &:hover,
    &:focus {
      gap: vw(8);
      padding-left: vw(4);
      color: darken(map-get($map: $c_list, $key: 'white'), 10);
    }
  }

  &__retry-button-icon-wrapper {
    width: vw(24, true);
    height: vw(24, true);
    overflow: hidden;
    border-radius: vw(24, true);
    background-color: map-get($map: $c_list, $key: 'lightBlack');
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 0 6px 0 rgba(214, 195, 255, 0.75);
    @include media-breakpoint-up(md) {
      width: vw(24);
      height: vw(24);
      border-radius: vw(24);
    }
  }

  &__retry-button-icon {
    color: map-get($map: $c_list, $key: 'white');
    background: transparent;
  }
}