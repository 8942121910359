@use 'styles/configs/' as *;

.activity-toys-machine {
  height: 100%;
  &__container {
    display: flex;
    justify-content: space-between;
    max-width: vw(1440, true);
    margin: 0 auto;
    padding-bottom: vw(80, true);
    height: 100%;
    flex-direction: row;
    align-items: flex-end;

    @include media-breakpoint-up(md) {
      max-width: vw(1440);
      padding-bottom: vw(80);
    }
  }
  &__machine {
    height: 100%;
  }
  &__machine-wrapper {
    margin: 0 auto;
    display: block;
    position: relative;
    height: calc(100% - #{vw(120, true)});
    @include media-breakpoint-up(md) {
      height: calc(100% - #{vw(120)});
    }
  }
  &__machine-shadow {
    position: absolute;
    height: 100%;
    left: 0;
    transform: translate(calc(-100% + #{vw(2, true)}), 0);
    @include media-breakpoint-up(md) {
      transform: translate(calc(-100% + #{vw(2)}), 0);
    }
  }
  &__window {
    position: absolute;
    top: 6.5%;
    left: 4%;
    width: 92%;
    height: 43%;
  }
  &__buttons {
    z-index: 1;
    position: absolute;
    width: 92%;
    bottom: 24%;
    left: 4%;
    @include media-breakpoint-up(md) {
      bottom: 18%;
    }
  }
}
