@use 'styles/configs/' as *;

.screen-mirror {
  height: 100%;

  h1 {
    font-family: "Changa", sans-serif;
    font-weight: 800;
    font-size: vw(46);
    margin-bottom: vw(10);
    color: $c_white;
    position: absolute;
    top: -1%;
    left: 20%;
  
    span {
      background-image: linear-gradient(0deg, transparent 12%, black 0px, black 89%, transparent 0px, transparent 100%);
    }
  }
}


