@use 'styles/configs/' as *;
@use 'sass:math';

$c_road: #1b0f26;

.screen-start {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  will-change: transform, opacity;
  transform: scale(1);
  &__background-sky {
    height: 100%;
    background-position: center top;
  }
  &__building-background {
    position: absolute;
    left: 0;
    bottom: 15%;
    width: 240%;
    @include media-breakpoint-up(md) {
      bottom: 10%;
      width: 120%;
    }
    @include media-breakpoint-up(md) {
      bottom: -10%;
      width: 100%;
    }
  }

  &__cloud {
    position: absolute;
    top: 8%;
    @include media-breakpoint-up(md) {
      top: 7%;
    }
    @include media-breakpoint-up(xl) {
      top: 4%;
    }
    &--1 {
      animation: cloud1 145s infinite;
    }
    &--2 {
      top: 4%;
      animation: cloud2 165s infinite;
    }
    &--3 {
      top: 6%;
      animation: cloud3 150s infinite;
    }
    &--4 {
      top: 10%;
      animation: cloud1 180s infinite;
      @include media-breakpoint-up(md) {
        top: 6%;
      }
      @include media-breakpoint-up(xl) {
        top: 2%;
      }
    }
  }
  &__road-wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 15%;
    background-color: $c_road;
    overflow: hidden;
    @include media-breakpoint-up(md) {
      height: 10%;
    }
  }
  &__road-rock {
    width: 280%;
    @include media-breakpoint-up(md) {
      width: 100%;
    }
  }
  &__bar-wrapper {
    position: absolute;
    bottom: calc(15% - #{vw(10, true)});
    width: 140%;
    left: 10%;
    @include media-breakpoint-up(md) {
      left: 50%;
      bottom: calc(10% - #{vw(10)});
      width: 80%;
      transform: translate(-50%, 0);
    }
    @include media-breakpoint-up(xxl) {
      width: 54%;
    }
  }
  &__bar {
    width: 100%;
    display: block;
    margin: 0 auto;
  }
  &__bar-enseigne {
    position: absolute;
    top: 14%;
    left: 7%;
    width: 25%;
  }
  &__flower {
    position: absolute;
    bottom: calc(15% - #{vw(12, true)});
    left: 11%;
    width: 20%;
    transform: translate(-50%, 0%);
    @include media-breakpoint-up(md) {
      width: 12%;
      bottom: calc(10% - #{vw(12)});
    }
    @include media-breakpoint-up(xxl) {
      width: 8%;
      left: 23%;
    }
  }
  &__flower-right {
    left: initial;
    right: 11%;
    transform: translate(50%, 0%);
    display: none;
    @include media-breakpoint-up(md) {
      display: block;
    }
    @include media-breakpoint-up(xxl) {
      right: 23%;
    }
  }
  &__chain {
    position: absolute;
    bottom: calc(15% - #{vw(16, true)});
    right: -5%;
    width: 50%;
    @include media-breakpoint-up(md) {
      width: 25%;
      right: initial;
      bottom: calc(10% - #{vw(32)});
      left: 50%;
      transform: translate(-50%, 0);
    }
    @include media-breakpoint-up(xxl) {
      width: 22%;
      bottom: calc(10% - #{vw(48)});
    }
  }
  &__lamp {
    left: 4%;
    bottom: 11%;
    width: 55%;
    position: absolute;
    @include media-breakpoint-up(md) {
      width: auto;
      left: 7%;
      height: 80%;
    }
    @include media-breakpoint-up(xxl) {
      height: 85%;
    }
  }
  &__lamp-right {
    display: none;
    left: inherit;
    @include media-breakpoint-up(md) {
      display: block;
      right: 7%;
    }
  }
  &__doorman {
    position: absolute;
    bottom: 8%;
    left: 48%;
    width: 18%;
    @include media-breakpoint-up(md) {
      left: 55%;
      width: 10%;
    }
    @include media-breakpoint-up(xxl) {
      width: 7%;
    }
  }
  .splash-screen {
    position: absolute;
    left: 50%;
    top: vw(75, true);
    transform: translate(-50%, 0);
    z-index: 1;
    gap: vw(32, true);
    flex-direction: column;

    @include media-breakpoint-up(md) {
      top: 25%;
      transform: translate(-50%, -50%);
      flex-direction: row;
      gap: vw(80);
    }
  }

  &.fade-enter-active,
  &.fade-exit-active{
    transition: all .5s;
  }
  &.fade-exit-active {
    opacity: 0;
    transform: scale(1.2);
  }
}

/* Animation Keyframes*/
@keyframes cloud2 {
  0% {
    left: 55%;
  }
  44% {
    left: 110%;
    opacity: 1;
  }
  45% {
    left: 110%;
    visibility: hidden;
    opacity: 0;
  }
  46% {
    left: -10%;
    visibility: hidden;
    opacity: 0;
  }
  52% {
    visibility: visible;
    left: 0%;
    opacity: 1;
  }
  100% {
    left: 55%;
  }
}
/* Animation Keyframes*/
@keyframes cloud1 {
  0% {
    left: 25%;
  }
  74% {
    left: 110%;
    opacity: 1;
  }
  75% {
    left: 110%;
    visibility: hidden;
    opacity: 0;
  }
  76% {
    left: -10%;
    visibility: hidden;
    opacity: 0;
  }
  82% {
    visibility: visible;
    left: 0%;
    opacity: 1;
  }
  100% {
    left: 25%;
  }
}
/* Animation Keyframes*/
@keyframes cloud3 {
  0% {
    left: 60%;
  }
  30% {
    left: 110%;
    opacity: 1;
  }
  31% {
    left: 110%;
    visibility: hidden;
    opacity: 0;
  }
  32% {
    left: -10%;
    visibility: hidden;
    opacity: 0;
  }
  37% {
    visibility: visible;
    left: 0%;
    opacity: 1;
  }
  100% {
    left: 60%;
  }
}
