@use 'styles/configs/' as *;

.splash-screen-presential {
  position: absolute;
  width: 100%;
  height: 100%;
  top: vw(-100, true) !important;
  left: 0 !important;
  transform: none !important;

  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  gap: vw(64, true);

  @include media-breakpoint-up(md) {
    top: vw(-100) !important;
    flex-direction: row;
    gap: vw(128);
  }

  &__logo {
    width: vw(210, true);
    height: vw(141, true);

    @include media-breakpoint-up(sm) {
      width: vw(317);
      height: vw(213);
    }
  }
  &__logo-svg {
    width: 100%;
    max-width: vw(225, true);

    @include media-breakpoint-up(md) {
      max-width: vw(225);
    }
  }
  &__img-filtre {
    max-width: vw(200, true);

    @include media-breakpoint-up(md) {
      max-width: vw(200);
    }
  }

  .t-center {
    text-align: center;
  }

  h2 {
    color: $c_white;
    margin-top:0;
    margin-bottom: vw(10);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(10);
    }
  }
}
