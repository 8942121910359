@use 'styles/configs/' as *;

.cocktail-ingredient {
    position: relative;
    z-index: 1;
    cursor: pointer;
    &.--is-disabled {
        opacity: 0.3;
        pointer-events: none;
    }
    &__icon {
        pointer-events: all;
        max-height: 80px;
        @include media-breakpoint-up(md) {
            max-height: vw(80);
        }
    }
    &__drag {
        opacity: 0.6;
        filter: drop-shadow(0 0 6px grey);
        @include media-breakpoint-up(xl) {
            display: none;
        }
    }

    &__drag-icon {
        position: relative;
        max-height: 80px;
        max-width: 80px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        @include media-breakpoint-up(md) {
            transform: none;
            max-height: vw(80);
            max-width: vw(80);
        }
    }
}
