@use 'styles/configs/' as *;

.action-bar {
  position: absolute;
  top: vw(22);
  left: vw(20);
  right: vw(20);
  z-index: 999;
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;

  @include media-breakpoint-down(md) {
    top: vw(22, true);
    left: vw(20, true);
    right: vw(20, true);
  }

  >div {
    display: flex;
    align-items: center;
    gap: vw(20);

    @include media-breakpoint-down(md) {
      gap: vw(20, true);
    }
  }

  &__logo-and-exit, &__logo-and-survey {
    display: flex;
    align-items: center;
    background: linear-gradient(359.16deg, #E5A80E 0%, #F5E800 100%);
    padding: vw(4) vw(4) vw(4) vw(12);
    width: auto;
    cursor: pointer;

    @include media-breakpoint-down(md) {
      padding: vw(4, true) vw(4, true) vw(4, true) vw(12, true);
    }

    @include media-breakpoint-up(lg) {
      padding: vw(12) vw(4) vw(12) vw(12);
    }

    >* {
      cursor: pointer;
      height: vw(24);
      padding: 0 vw(4);

      @include media-breakpoint-down(md) {
        height: vw(24, true);
        padding: 0 vw(4, true);
      }

      @include media-breakpoint-up(lg) {
        height: vw(36);
      }
    }

    &>object {
      pointer-events: none;
    }

    &:hover,
    &:focus {
      .action-bar__exit-button {
        padding-right: vw(2);
        filter: drop-shadow(0 0 vw(2) rgba(map-get($map: $c_list, $key: "white"), 0.4));

        @include media-breakpoint-down(md) {
          padding-right: vw(2, true);
          filter: drop-shadow(0 0 vw(2, true) rgba(map-get($map: $c_list, $key: "white"), 0.4));
        }

        @include media-breakpoint-up(lg) {
          padding-right: vw(8);
        }
      }
    }
  }

  &__logo-and-survey {
    background: linear-gradient(359.16deg, #d40afd 0%, #0bf2d6 100%);
  }

  &__exit-button {
    color: map-get($map: $c_list, $key: 'white');
    height: auto;
    filter: drop-shadow(0 0 0 rgba(map-get($map: $c_list, $key: "white"), 0.4));
    padding-right: vw(6);
    font-size: vw(14);
    transition: all 0.25s ease-in;

    @include media-breakpoint-down(md) {
      padding-right: vw(6, true);
      font-size: vw(14, true);
    }

    @include media-breakpoint-up(lg) {
      padding-right: vw(12);
      font-size: vw(20);
    }
  }

  &__only-music {
    justify-content: flex-end;
  }

  &__music {
    background-color: map-get($map: $c_list, $key: "secondary");
    height: auto;
  }

  &__music-button {
    cursor: pointer;
    display: block;
    background-color: transparent;
    border: none;
    padding: vw(4) vw(12) vw(4) vw(16);
    font-size: vw(24);
    color: map-get($map: $c_list, $key: "warning");
    filter: drop-shadow(0 0 0 rgba(map-get($map: $c_list, $key: "warning"), 0.4));
    transition: all 0.25s ease-in;

    @include media-breakpoint-down(md) {
      font-size: vw(24, true);
      padding: vw(4, true) vw(12, true) vw(4, true) vw(16, true);
    }

    @include media-breakpoint-up(lg) {
      font-size: vw(36);
      padding: vw(10) vw(34) vw(12) vw(24);
    }

    &:hover,
    &:focus {
      padding-left: vw(12);
      filter: drop-shadow(0 0 vw(2) rgba(map-get($map: $c_list, $key: "warning"), 0.4));

      @include media-breakpoint-down(md) {
        padding-left: vw(12, true);
        filter: drop-shadow(0 0 vw(2, true) rgba(map-get($map: $c_list, $key: "warning"), 0.4));
      }

      @include media-breakpoint-up(lg) {
        padding-left: vw(20);
      }
    }
  }
}