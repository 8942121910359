@use 'styles/configs/' as *;

.bomb-timer {
    border: vw(4, true) solid map-get($map: $c_list, $key: 'lightBlack');
    background-color: map-get($map: $c_list, $key: 'black');
    min-width: vw(80, true);
    text-align: center;

    @include media-breakpoint-up(md) {
        border: vw(4) solid map-get($map: $c_list, $key: 'lightBlack');
        min-width: vw(80);
    }
}
