@use 'styles/configs/' as *;

body {
  touch-action:none;
  overscroll-behavior-y: contain;
  height: calc(var(--vh, 1vh) * 100);
  min-height: -webkit-fill-available;
}

.App {
  font-family: $f_main;
}

ul,
ol {
  list-style: none;
  padding-left: vw(32);
  @include text(14, 16);

  @include media-breakpoint-down(md) {
    padding-left: vw(32, true);
  }

  li {
    margin-top: vw(8);
    display: flex;
    align-items: stretch;

    @include media-breakpoint-down(md) {
      margin-top: vw(8, true);
    }

    &::before {
      content: '\2022';
      color: map-get($map: $c_list, $key: 'error');
      font-weight: bold;
      display: inline-block;
      vertical-align: text-top;
      font-size: vw(32);
      margin-left: vw(-32);
      margin-right: vw(16);

      @include media-breakpoint-down(md) {
        font-size: vw(32, true);
        margin-left: vw(-32, true);
        margin-right: vw(16, true)
      }
    }
  }
}

[role="presentation"] {
  pointer-events: none;

  .modal {
    pointer-events: auto;
  }
}

.modal {
  width: 100%;
  max-width: vw(450, false, 450px, 450px);
  min-width: 450px;
  box-sizing: border-box;
  padding: vw(24);
  background-color: map-get($map: $c_list, $key: 'blackOpacity');
  @include text(16, 23);
  font-family: $f_inter;
  font-weight: map-get($map: $f_weights, $key: 'medium');
  color: map-get($c_list, 'white');
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: vw(10);

  @include media-breakpoint-down(md) {
    max-width: calc(100% - #{vw(40, true)});
    min-width: unset;
    padding: vw(24, true);
    border-radius: vw(10, true);
  }

  h2{
    font-family: $f_main;
    line-height: 1.25;
    margin-top: 0;
  }
  p{
    margin-top: 0;
    &.modal__text{
      margin: 0 0 vw(24);
      @include media-breakpoint-down(md) {
        margin: 0 0 vw(24, true);
      }
      p{
        margin-bottom: 10px;
      }
    }

    &.modal__additionalText{
      text-align: center;
      @include text(14, 17);
      margin: vw(10) 0 0;

      @include media-breakpoint-down(md) {
        margin: vw(10, true) 0 0;
      }
    }
  }

  .button{
    width: 100%;
    box-sizing: border-box;

    svg{
      path{
        &:first-child{
          fill: map-get($map: $c_list, $key: 'white');
          opacity: 1;
        }
        &:last-child{
          fill: map-get($map: $c_list, $key: 'black');
        }
      }
    }
  }

  ul,
  ol {
    list-style: none;
    padding-left: vw(32);
    @include text(16, 18);

    @include media-breakpoint-down(md) {
      padding-left: vw(32, true);
    }
    li {
      margin-top: vw(8);
      display: flex;
      align-items: stretch;

      @include media-breakpoint-down(md) {
        margin-top: vw(8, true);
      }
      &::before {
        content: '\2022';
        color: map-get($map: $c_list, $key: 'error');
        font-weight: bold;
        display: inline-block;
        vertical-align: text-top;
        width: vw(16);
        font-size: vw(32);
        margin-left: vw(-32);

        @include media-breakpoint-down(md) {
          width: vw(16, true);
          font-size: vw(32, true);
          margin-left: vw(-32, true);
        }
      }
    }
  }

  .screen-bar__defi-button__content{
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: vw(10);

    @include media-breakpoint-down(md) {
      gap: vw(10, true);
    }

    img {
      max-width: 100%;
      align-self: center;
      margin-bottom: vw(14);

      @include media-breakpoint-down(md) {
        margin-bottom: vw(14, true);
      }
    }

    a:not(.--outline), button:not(.--outline){
      .svg{
        pointer-events: none;
        position: relative;
        top: vw(2);
        margin-left: vw(14);

        @include media-breakpoint-down(md) {
          top: vw(2, true);
          margin-left: vw(14, true);
        }
      }
    }

    p:last-child{
      margin-top: vw(14);

      @include media-breakpoint-down(md) {
        margin-top: vw(14, true);
      }
    }
  }
}
.modal-close {
  position: absolute;
  top: vw(-56);
  right: 0;
  width: unset !important;
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: vw(40);
  color: map-get($map: $c_list, $key: 'secondary');
  filter: drop-shadow(0 0 vw(2) map-get($map: $c_list, $key: 'error'));

  @include media-breakpoint-down(md) {
    top: vw(-56, true);
    font-size: vw(40, true);
  }
}
