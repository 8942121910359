@use 'styles/configs/' as *;

.activity-quizz {
    display: flex;
    &__wrapper {
        padding: vw(80, true) vw(20, true);
        margin: 0 auto;
        width: 100%;
        max-width: vw(1440, true);

        @include media-breakpoint-up(md) {
            padding: vw(80) vw(40);
            max-width: vw(1440);
        }
    }
}
