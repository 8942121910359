@use 'styles/configs/' as *;

.activity-mirror {
  background-size: auto 100%;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  @include media-breakpoint-up(md) {
    background-size: cover;
  }
  &__mirror {
    position: relative;
    text-align: center;
  }
  &__mirror-img {
    pointer-events: none;
    max-width: calc(94% - #{vw(40, true)});
    width: vw(440, true);
    position: relative;
    z-index: 1;
    @include media-breakpoint-up(md) {
      width: vw(440);
      max-width: calc(100% - #{vw(40)});
    }
  }
  &__mirror-content {
    position: absolute;
    height: 88%;
    width: calc(90% - #{vw(58, true)});
    border-radius: vw(120, true);
    overflow: hidden;
    transform: translate(-50%, -50%);
    top: 52%;
    left: 50%;
    background-color: #dfdfdf;
    @include media-breakpoint-up(md) {
      width: calc(90% - #{vw(40)});
      border-radius: vw(140);
    }
  }
  &__phone-number {
    color: map-get($map: $c_list, $key: 'white');
    font-size: 1rem;

    @include media-breakpoint-up(md) {
      font-size: 1rem;
    }
  }
  &__content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: auto;
    height: 100%;
    animation-fill-mode: forwards;
    opacity: 0;
  }
}

@keyframes mirrorFadeOut {
  0% {
    opacity: 1;
  }
  40% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
