@use 'styles/configs/' as *;

.scene-loader {
  width: 100vw;
  max-height: -webkit-fill-available;
  height: calc(var(--vh, 1vh) * 100);
  background: map-get($map: $c_list, $key: 'background');
  overflow: hidden;

  &.--presential {
    background-color: #000;
    background-size: cover;
  }
}

// Default animation for scene.
.fade-enter{
  opacity: 0;
}
.fade-exit{
  opacity: 1;
}
.fade-enter-active{
  opacity: 1;
}
.fade-exit-active{
  opacity: 0;
}
.fade-enter-active,
.fade-exit-active{
  transition: opacity 500ms;
}