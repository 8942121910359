@use 'styles/configs/' as *;

.toys-machine-button {
    width: 100%;
    background: map-get($map: $c_list, $key: 'secondary');
    display: flex;
    align-items: center;
    justify-content: center;

    &__arrow,
    &__drop-btn {
        background-color: transparent;
        cursor: pointer;
        border: none;
        position: relative;
        &.--is-disabled {
            opacity: 0.7;
            cursor: not-allowed;
        }
        &:hover,
        &:focus {
            &.--is-disabled {
                box-shadow: none;
            }
            > object {
                filter: drop-shadow( 0 0 2px #{rgba( $color: map-get( $c_list, 'warning'), $alpha: 0.4 )})
            }
        }
        &:active {
            > object {
                filter: drop-shadow(0 4px 0.5rem #{map-get($c_list, 'black')});
            }
        }
        > object {
            @include transit();
            pointer-events: none;
        }
    }
    &__drop-btn {
        width: 90px;
        @include media-breakpoint-up(md) {
            width: 140px;
        }
        > object {
            width: 100%;
            height: auto;
        }
    }
}
