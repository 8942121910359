@use 'styles/configs/' as *;

.activity-fridge {
    position: relative;
    height: 100%;
    width: 100%;
    
    &__freezer-wrapper {
        position: absolute;
        width: 150%;
        bottom: vw(-20, true);
        right: 0;
        @include text(34, 43);
        color: map-get($map: $c_list, $key: "error");
        text-shadow: 0 0 vw(4, true) 0 rgba($color: map-get($map: $c_list, $key: "error"), $alpha: 0.7);
        font-family: $f_cute;

        @include media-breakpoint-up(md) {
            width: 80%;
            bottom: vw(-80);
            right: 40%;
            text-shadow: 0 0 vw(4) 0 rgba($color: map-get($map: $c_list, $key: "error"), $alpha: 0.7);
        }
        @include media-breakpoint-up(lg) {
            width: 65%;
            left: 0;
            right: inherit;
        }
        @include media-breakpoint-up(xl) {
            max-width: vw(720);
            max-height: 100%;
            left: 50%;
            transform: translate(-100%, 0);
        }
        @include media-breakpoint-up(xxl) {
            max-width: vw(800);
        }

    }
    &__chain {
        position: absolute;
        top: 50%;
        right: -4%;
        width: 72%;
        opacity: 1;
        transition: all 0.25s ease-in;
        @include media-breakpoint-up(md) {
            top: 45%;
        }
        &.--is-open {
            top: 100%;
            opacity: 0;
        }
    }
    &__confetis-wrapper {
        position: absolute;
        right: -25%;
        width: 50%;
        animation: confetisFadeIn 2s;
        @include media-breakpoint-up(md) {
            width: 100%;
        }
    }
    &__banner-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: calc(100% - #{vw(40, true)});
        max-width: vw(1440, true);
        padding: 0 vw(20, true);
        box-sizing: border-box;
        margin: 0 auto;
        align-items: center;
        position: absolute;
        z-index: 10;
        left: 50%;
        bottom: vw(100, true);
        transform: translate(-50%, 0);

        @include media-breakpoint-up(md) {
            width: calc(100% - #{vw(40)});
            max-width: vw(1440);
            padding: 0 vw(20);
            bottom: vw(100);
        }
    }
    &__banner {
        position: absolute;
        right: 0;
        bottom: 0;
        opacity: 0;
        background-color: map-get($map: $c_list, $key: "white");
        width: calc(100% - #{vw(40, true)});
        box-shadow: 0 0 10px 0 rgba($color: map-get($c_list, "warning"), $alpha: 0.7);
        border: 1px solid map-get($c_list, "warning");
        transition: opacity 0.25s ease-in;
        padding: 0 vw(12, true);
        @include media-breakpoint-up(md) {
            width: auto;
            transform: none;
            max-width: vw(270);
            box-shadow: 0 0 10px 0 rgba($color: map-get($c_list, "warning"), $alpha: 0.7);
            padding: 0 vw(12);
        }
    }
    &__freezer {
        max-height: 100%;
        width: 100%;
        display: block;
    }
    &__chair-table {
        position: absolute;
        visibility: hidden;
        z-index: 1;
        @include media-breakpoint-up(md) {
            visibility: visible;
            transform: translate(75%, 50%);
            bottom: 0;
            right: 0;
        }
        @include media-breakpoint-up(lg) {
            transform: translate(55%, 45%);
        }
        @include media-breakpoint-up(xl) {
            transform: translate(35%, 45%);
        }
        @include media-breakpoint-up(xxl) {
            transform: translate(5%, 35%);
        }
    }
    &__light {
        position: absolute;
        visibility: hidden;
        z-index: 2;
        @include media-breakpoint-up(md) {
            visibility: visible;
            transform: translate(75%, -45%);
            top: 0;
            right: 25%;
        }
        @include media-breakpoint-up(xl) {
            transform: translate(50%, -35%);
        }
        @include media-breakpoint-up(xxl) {
            right: 30%;
            transform: translate(0, -25%);
        }
    }
}

@keyframes confetisFadeIn {
    0% {
        opacity: 0;
        top: 40%;
        transform: scale(0);
    }
    20% {
        opacity: 0.7;
    }
    30% {
        transform: scale(1.2);
        opacity: 1;
    }
    40% {
        transform: scale(1);
        opacity: 1;
    }
    41% {
        top: 40%;
        transform: scale(1);
        opacity: 1;
    }
    50% {
        top: 40%;
    }
    70% {
        opacity: 1;
    }
    100% {
        top: 100%;
        opacity: 0;
    }
}
