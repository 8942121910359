@use 'styles/configs/' as *;

.quizz-results {
  height: 100%;

  &__container{
    display: flex;
    justify-content: space-around;
    max-width: vw(1440, true);
    margin: 0 auto;
    padding-bottom: vw(80, true);
    height: auto;
    flex-direction: column;
    align-items: center;

    @include media-breakpoint-up(md) {
      padding-top: 100px;
    }

    @include media-breakpoint-up(lg) {
      max-width: vw(1440);
      flex-direction: row;
      padding-bottom: vw(80);
      height: 100%;
    }
  }

  &__title {
    font-family: $f_secondary;
    font-weight: map-get($map: $f_weights, $key: 'regular');
    color: map-get($c_list, 'white');
  }
  &__images {
    width: unset;
    max-width: 100%;
    box-sizing: border-box;
    display: block;
    position: relative;
    margin: 0 auto 0 0;

    @include media-breakpoint-up(lg) {
      max-width: vw(540);
      max-height: none;
      margin: 0;
    }
  }
  &__description {
    width: 100%;
    max-width: vw(540, true);
    display: block;
    position: relative;
    color: map-get($map: $c_list, $key: 'white');
    font-family: $f_inter;
    font-weight: map-get($map: $f_weights, $key: 'medium');
    margin-bottom: vw(20, true);
    @include text(14, 16);
    @include media-breakpoint-up(lg) {
      max-width: vw(540);
      margin-bottom: vw(20);
      @include text(18, 23);
    }
  }

  &__actions{
    z-index: 1;
    position: absolute;
    bottom: vw(25, true);
    left: vw(20, true);
    right: vw(20, true);

    display: flex;
    flex-direction: column;
    gap: vw(8, true);

    button {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: vw(16);
      bottom: vw(25);
      right: unset;
      left: 50%;
      transform: translate(-50%, 0);

      button {
        width: unset;
      }
    }
  }
}
