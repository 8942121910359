@use 'styles/configs/' as *;

.activity-splash-action {
  position: absolute;
  width: 100%;
  bottom: 20px;
  z-index: map-get($map: $zindex, $key: 'header');
  @include media-breakpoint-up(md) {
    bottom: 40px;
  }
  &__container {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    max-width: vw(1440);
    padding: 0 vw(20);
    margin: 0 auto;
    align-items: center;
  }
}
