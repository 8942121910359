@use 'styles/configs/' as *;

.activity-cocktail {
  width: 100vw;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  position: relative;

  &__message-wrapper {
    width: calc(100% - #{vw(40, true)});
    max-width: vw(1440, true);
    position: absolute;
    left: 50%;
    bottom: vw(74, true);
    transform: translate(-50%, 0);
    display: flex;
    justify-content: flex-end;
    @include media-breakpoint-up(md) {
      bottom: vw(100);
      max-width: vw(1440);
      width: calc(100% - #{vw(40)});
    }
    @include media-breakpoint-up(lg) {
      bottom: vw(110);
    }
  }

  &__message {
    background-color: map-get($map: $c_list, $key: 'white');
    width: 100%;
    //box-shadow: 0 0 10px 0 rgba($color: map-get($c_list, 'warning'), $alpha: 0.7);
    @include text(18, 22, 'medium', null, 'inter');
    //border: 1px solid map-get($c_list, 'warning');
    transition: opacity 0.25s ease-in;
    padding: 24px;
    border-radius: 12px;

    @include media-breakpoint-up(md) {
      //box-shadow: 0 0 10px 0 rgba($color: map-get($c_list, 'warning'), $alpha: 0.7);
      @include text(20, 24, 'medium', null, 'inter');
      align-self: flex-end;
      width: auto;
      max-width: 333px;
    }

    span {
      background-color: map-get($c_list, 'warning');
    }
  }
}
