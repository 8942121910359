@use 'styles/configs/' as *;

.activity-vape-puzzle {
  width: 100vw;
  height: 100%;

  &__container {
    max-width: vw(1440, true);
    margin: 0 auto;
    padding-bottom: vw(80, true);
    top: vw(75, true);
    position: relative;
    height: calc(100% - #{vw(150, true)});

    @include media-breakpoint-up(md) {
      max-width: vw(1440);
      padding-bottom: vw(80);
      top: vw(140);
      height: calc(100% - #{vw(220)});
    }
  }

  &__vape-container {
    position: relative;
    width: calc(100% - #{vw(690, true)});
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      width: calc(100% - #{vw(690)});
    }

    @include media-breakpoint-down(md) {
      left: vw(-70, true);
    }
  }

  &__piece {
    position: absolute;
    z-index: 1;

    .activity-vape-puzzle__dripTip{
      width: 43px;

      @include media-breakpoint-up(md) {
        width: vw(43);
      }
    }

    .activity-vape-puzzle__podRef {
      width: 44px;

      @include media-breakpoint-up(md) {
        width: vw(44);
      }
    }

    .activity-vape-puzzle__battery, .activity-vape-puzzle__power-indicator {
      width: 48px;

      @include media-breakpoint-up(md) {
        width: vw(48);
      }
    }

    img {
      display: block;
    }
  }

  &__vape-wrapper {
    position: absolute;
    right: 0;
    top: vw(20, true);
    width: auto;
    display: inline-block;

    @include media-breakpoint-up(md) {
      top: vw(20);
    }

    .svg {
      width: 50px;
      height: 356px;

      @include media-breakpoint-up(md) {
        width: vw(50);
        height: vw(356);
      }
    }
  }

  &__vape-blank {
    width: 50%;
  }

  &__vape-p1 {
    top: -19px;
    height: 86px;
    position: absolute;
    width: 83px;
    left: -17px;

    @include media-breakpoint-up(md) {
      top: vw(-19);
      height: vw(86);
      width: vw(83);
      left: vw(-17);
    }

    &.--is-dragging {
      z-index: 2;
    }
  }

  &__vape-p2 {
    top: 12px;
    height: 99px;
    position: absolute;
    width: 84px;
    left: -17px;

    @include media-breakpoint-up(md) {
      top: vw(12);
      height: vw(99);
      width: vw(84);
      left: vw(-17);
    }
    &.--is-dragging {
      z-index: 2;
    }
  }

  &__vape-p3 {
    top: 92px;
    width: 84px;
    left: -20px;
    height: 258px;
    position: absolute;

    @include media-breakpoint-up(md) {
      top: vw(92);
      width: vw(84);
      left: vw(-20);
      height: vw(258);
    }
    &.--is-dragging {
      z-index: 2;
    }
  }

  &__vape-p4 {
    top: 305px;
    height: 71px;
    width: 88px;
    position: absolute;
    left: -18px;

    @include media-breakpoint-up(md) {
      top: vw(305);
      height: vw(71);
      width: vw(88);
      left: vw(-18);
    }
    &.--is-dragging {
      z-index: 2;
    }
  }

  &__drag {
    z-index: 10;
    opacity: 0.6;
    filter: drop-shadow(0 0 6px grey);
    @include media-breakpoint-up(xl) {
      display: none;
    }
  }

  &__drag-icon {
    position: relative;
    display: block;
    max-width: vw(50, true);
    @include media-breakpoint-up(md) {
      transform: none;
      max-width: vw(50)
    }
  }
}
