@use 'styles/configs/vars' as *;

.image {
  position: relative;
  img {
    display: block;
  }
  &--ratio {
    height: max-content;
    &:before {
      content: '';
      display: block;
    }
    img {
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    @each $key, $val in $image_ratio_list {
      &--#{$key}:before {
        padding-bottom: #{$val};
      }
    }
  }
}
