@use 'styles/configs/' as *;

.quizz-question {
    text-align: left;
    width: 100%;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        max-width: vw(800);
    }
    &__title {
        font-family: $f_secondary;
        font-weight: map-get($map: $f_weights, $key: 'regular');
        color: map-get($c_list, 'white');
    }
    &__image {
        width: 100%;
        max-width: vw(540, true);
        display: block;
        position: relative;
        @include media-breakpoint-up(md) {
            max-width: vw(540);
        }

        img {
            width: auto;
            max-width: 100%;
            max-height: vw(400, true);

            @include media-breakpoint-up(md) {
                max-height: vw(400);
            }
        }
    }
    &__form {
        margin-top: vw(20, true);
        @include media-breakpoint-up(md) {
            margin-top: vw(20);
        }
    }
    .MuiFormGroup-root {
        color: map-get($map: $c_list, $key: 'primary');
        text-shadow: 0 0 2px rgba($color: map-get($c_list, 'primary'), $alpha: 0.6);
    }
    .MuiIconButton-label {
        filter: drop-shadow( 0 0 2px);
        color: map-get($map: $c_list, $key: 'primary');
    }
    .MuiIconButton-colorSecondary {
        &:hover {
            background-color: darken(rgba($color: map-get($c_list, 'primary'), $alpha: 0.1), 20);
        }
        &.Mui-checked {
            color: darken(rgba($color: map-get($c_list, 'primary'), $alpha: 0.8), 25);
            &:hover {
                background-color: darken(rgba($color: map-get($c_list, 'primary'), $alpha: 0.3), 25);
            }   
        }
    }
}
