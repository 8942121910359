@use "sass:map";

@use 'styles/configs/vars' as *;
@use 'functions' as *;

@mixin text($size: null, $lineHeight: null, $weight: null, $letterSpacing: null, $family: null) {
  @if $family != null and map.get($f_list, $family) != null {
    font-family: #{map.get($f_list, $family)};
  } @else if($family != null) {
    @error 'Need a valid font weight value from $f_list var';
  }

  @if $size != null {
    font-size: textVw($size);
  }

  @if $lineHeight != null {
    line-height: textVw($lineHeight);
  }

  @if $weight != null and map.get($f_weights, $weight) != null {
    font-weight: #{map.get($f_weights, $weight)};
  } @else if($weight != null) {
    @error 'Need a valid font weight value from $f_weights var';
  }

  @if $letterSpacing != null {
    letter-spacing: textVw($letterSpacing);
  }
}

@mixin btn_state($base, $hover, $actif, $outline, $type: 'raised') {
  @if $type == 'raised' {
    &:hover,
    &[aria-selected='true'] {
      border-color: $hover;
      background-color: $hover;
    }

    &:focus,
    &.--active {
      border-color: $outline;
      background-color: $hover;
    }

    &:active {
      border-color: $actif;
      background-color: $actif;
    }
  }

  @if $type == 'outline' {
    &:hover,
    &[aria-selected='true'] {
      color: $outline;
      border-color: $actif;
      background-color: $base;
    }

    &:focus,
    &.--active {
      color: $outline;
      border-color: $hover;
      background-color: $base;
    }

    &:active {
      color: $outline;
      border-color: $base;
      background-color: $base;
    }
  }
}

@mixin transit($prop: all, $dur: 250ms, $ease: ease-in-out) {
  transition: $prop $dur $ease;
}

@mixin text_selection($bg_color, $txt_color) {
  ::-moz-selection {
    background: $bg_color;
    color: $txt_color;
  }
  ::selection {
    background: $bg_color;
    color: $txt_color;
  }
}

@mixin scrollbar($orientation: '-y', $color: $c_grey, $size: 3px) {
  overflow#{$orientation}: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: $size;
    height: $size;
    background-color: rgba($color, 0);
  }
  &::-webkit-scrollbar:hover {
    background-color: rgba($color, 0.09);
  }
  &::-webkit-scrollbar-thumb {
    -webkit-box-shadow: inset 0 0 $size rgba($color, 0.3);
    background-color: rgba($color, 0.5);
  }
}

@mixin overlay(
  $color: $c_white,
  $pos: 'after',
  $height: 100%,
  $width: 100%,
  $zindex: 1,
  $display: block
) {
  &:#{$pos} {
    display: $display;
    content: ' ';
    position: absolute;
    width: $width;
    height: $height;
    background: $color;
    z-index: $zindex;
    @content;
  }
}

@mixin toggle_ico($color: $c_black, $hover: $c_primary) {
  position: relative;
  i {
    font-size: 1.5em;
    color: $color;
    margin-left: 0;
    transform-origin: 50% 50%;
    &:last-child {
      position: absolute;
      font-size: 1.8em;
      transform: scale(0, 0);
    }
  }
  &:hover i {
    color: $hover;
  }
  &[aria-expanded='true'] i {
    &:first-child {
      transform: scale(0, 0);
    }
    &:last-child {
      transform: scale(1, 1);
    }
  }
}

@mixin equal_icon($size: 40px) {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: $size;
  line-height: $size;
  width: $size;
  height: $size;
}
