@use 'styles/configs/' as *;

.registration-card {
  position: relative;
  display: inline-block;
  transform: rotate(-2deg) translate(-50%, 0);
  width: 110%;
  max-width: vw(540, true);
  left: 40%;
  animation: cardFadeIn 1s ease-in;

  @include media-breakpoint-up(md) {
    width: 100%;
    left: 50%;
    transform: rotate(-5deg) translate(-50%, 0);
    max-width: 39.375rem;
  }
  &.--is-keyboard-open {
    position: absolute;
    bottom: vw(20, true);

    @include media-breakpoint-up(md) {
      bottom: vw(20);
    }
  }
  &__card-image {
    width: 100%;
    filter: drop-shadow(0 vw(2, true) vw(8, true) rgba(map-get($c_list, 'black'), 0.4));

    @include media-breakpoint-up(md) {
      filter: drop-shadow(0 vw(2) vw(8) rgba(map-get($c_list, 'black'), 0.4));
    }
  }
  &__form {
    width: 100%;
    text-align: left;
    max-width: calc(60% - #{vw(20, true)});
    max-height: 100%;
    position: absolute;
    z-index: 1;
    left: calc(30% + 1.25rem);
    top: 50%;

    @include media-breakpoint-up(md) {
      max-width: calc(60% - #{vw(20)});
      left: calc(35% + #{vw(20)});
      top: 60%;
    }
  }
  &__text {
    font-family: $f_secondary;
    font-weight: map-get($map: $f_weights, $key: 'regular');
    margin: 0;
    color: map-get($map: $c_list, $key: 'white');
    @include text(20, 22);
    @include media-breakpoint-up(md) {
      @include text(24, 25);
    }
  }
  &__subtext {
    color: map-get($map: $c_list, $key: 'secondary');
    margin: vw(8, true) 0 0 0;
    @include text(14, 15);
    @include media-breakpoint-up(md) {
      margin: vw(13) 0 0 0;
      @include text(16, 17);
    }
    span {
      margin: 0 vw(10, true);

      @include media-breakpoint-up(md) {
        margin: 0 vw(10);
      }
    }
  }
  &__select {
    width: 100%;
    background-color: map-get($map: $c_list, $key: 'white');
    margin-top: vw(4, true);

    @include media-breakpoint-up(md) {
      margin-top: vw(4);
    }

    &.MuiInput-underline{
      &:before{
        display: none;
      }
    }

    &:after  {
      display: block;
      content: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iOCIgdmlld0JveD0iMCAwIDE2IDgiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+CjxwYXRoIGQ9Ik0xNC44Mzk4IDAuNTgwMDc4TDguMDAwMDggNy40MTk4NEwxLjE2MDMyIDAuNTgwMDc4IiBzdHJva2U9ImJsYWNrIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") !important;
      color: black;
      position: absolute;
      top: 50% !important;
      right: vw(20, true) !important;
      left: unset !important;
      bottom: unset !important;
      transform: translateY(-50%) !important;
      border: none !important;

      @include media-breakpoint-up(md) {
        right: vw(20) !important;
      }
    }

    &.Mui-focused{
      .MuiNativeSelect-select {
        border-radius: vw(50, true);
        border: 1px solid map-get($map: $c_list, $key: 'warning');
        background-color: map-get($map: $c_list, $key: 'warning');

        @include media-breakpoint-up(md) {
          border-radius: vw(50);
        }
      }
    }

    .MuiNativeSelect-select {
      width: 100%;
      font-weight: map-get($map: $f_weights, $key: 'extra-bold');
      padding: vw(8.5, true) vw(58, true) !important;
      border: 1px solid map-get($map: $c_list, $key: 'black');
      border-radius: vw(50, true);
      @include text(18, 25);
      font-family: $f_main !important;
      text-align: center;
      transition: all 0.15s ease-in;

      @include media-breakpoint-up(md) {
        padding: vw(8.5) vw(58) !important;
        border-radius: vw(50);
      }

      &:hover {
        border: 1px solid map-get($map: $c_list, $key: 'warning');
        background-color: map-get($map: $c_list, $key: 'warning');
      }
    }
    .MuiNativeSelect-icon {
      path{
        display: none;
      }
    }
    &.--small {
      .MuiNativeSelect-select {
        padding: vw(10, true) vw(6, true) vw(6, true) vw(6, true);
        @include media-breakpoint-up(md) {
          padding: vw(10) vw(16) vw(6) vw(16);
        }
      }
    }
  }
  &__input.MuiTextField-root {
    display: block;
    width: 100%;
    & + & {
      margin-top: vw(2, true);
      @include media-breakpoint-up(md) {
        margin-top: vw(6);
      }
    }
    label {
      display: none;
      + .MuiInput-formControl {
        width: 100%;
        background-color: map-get($map: $c_list, $key: 'white');
        margin-top: vw(4, true);

        @include media-breakpoint-up(md) {
          margin-top: vw(4);
        }
      }
    }

    &.--small {
      width: auto;
      display: inline-block;
      max-width: vw(80, true);

      @include media-breakpoint-up(md) {
        max-width: vw(80);
      }
      .MuiInputBase-input {
        width: 100%;
        font-family: Helvetica, Arial, sans-serif;
        color: #282e41;
        border: 2px solid map-get($map: $c_list, $key: 'black');
        padding: vw(10, true) vw(6, true) vw(6, true) vw(6, true);
        @include text(18, 22);
        @include media-breakpoint-up(md) {
          @include text(21, 26);
          padding: vw(10) vw(6) vw(6) vw(6);
        }
      }
    }
    .MuiInputBase-input {
      width: 100%;
      font-size: vw(21, true);
      line-height: vw(26, true);
      font-family: Helvetica, Arial, sans-serif;
      color: #282e41;
      padding: vw(10, true) vw(16, true) vw(6, true) vw(16, true);
      border: 2px solid map-get($map: $c_list, $key: 'black');

      @include media-breakpoint-up(md) {
        font-size: vw(21);
        line-height: vw(26);
        padding: vw(10) vw(16) vw(6) vw(16);
      }
      &[type='number'],
      &[type='number']::-webkit-inner-spin-button,
      &[type='number']::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: textfield;
        margin: 0;
      }
      &::placeholder {
        opacity: 1;
      }
    }
  }
  &__small-field {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    .registration-card__subtext {
      margin-top: vw(18, true);
      margin-right: vw(4, true);
      display: flex;

      @include media-breakpoint-up(md) {
        margin-top: vw(18);
        margin-right: vw(4);
      }
    }
    &:first-child {
      .registration-card__subtext span {
        margin-left: 0;
      }
    }
  }
  &__divised-section {
    display: flex;
    justify-content: space-between;
    margin-top: vw(8, true);

    @include media-breakpoint-up(md) {
      margin-top: vw(8);
    }
  }
}

@keyframes cardFadeIn {
  0% {
    opacity: 0;
    bottom: vw(-300, true);
    transform: rotate(-25deg) translate(-50%, 0);

    @include media-breakpoint-up(md) {
      bottom: vw(-300);
    }
  }
  60% {
    opacity: 1;
  }
  90% {
    bottom: 0;
  }
  100% {
    opacity: 1;
    transform: rotate(-5deg) translate(-50%, 0);
  }
}
