@use 'styles/configs/' as *;

.cocktail-barman {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 375px;
    height: auto;
    display: block;
    @include media-breakpoint-up(sm) {
        bottom: vw(-80, true);
        width: 100%;
    }
    @include media-breakpoint-up(md) {
        bottom: 0;
    }
    @include media-breakpoint-up(lg) {
        bottom: vw(-80);
    }
    @include media-breakpoint-up(xl) {
        bottom: vw(-110);
    }

    &__table-wrapper {
        width: 180%;
        margin-left: -55%;
        @include media-breakpoint-up(md) {
            text-align: center;
            width: 100%;
            margin-left: 0;
        }
    }

    &__table {
        width: 100%;
        max-width: vw(1200, true , 0px, 400vw);
        pointer-events: none;
        @include media-breakpoint-up(md) {
            max-width: vw(1200);
        }
    }

    &__drop-zone {
        position: absolute;
        left: 60%;
        top: 30%;
        transform: translate(-50%, -50%);
        width: auto;
        display: flex;
        flex-direction: row;
        gap: vw(20, true);
        @include media-breakpoint-up(md) {
            max-width: 50%;
            top: 32%;
            left: 67%;
            gap: vw(20);
        }
        @include media-breakpoint-up(xl) {
            left: 65%;
        }
    }
}
