@use 'styles/configs/' as *;

$c_background: #ffcb06;

.screen-end-top {
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: $c_background;
  position: relative;
  display: flex;
  align-items: center;
  &__splash-1 {
    height: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    animation: smoke1 15s infinite;
    mix-blend-mode: darken;
    @include media-breakpoint-up(lg) {
      height: 150%;
    }
    @include media-breakpoint-up(xxl) {
      height: 200%;
    }
  }
  &__splash-2 {
    display: none;
    height: 110%;
    position: absolute;
    left: 0;
    top: 0;
    mix-blend-mode: darken;
    animation: smoke2 20s infinite;
    @include media-breakpoint-up(md) {
      display: block;
    }
    @include media-breakpoint-up(lg) {
      height: 150%;
    }
    @include media-breakpoint-up(xxl) {
      height: 200%;
    }
  }
  &__content-wrapper {
    position: relative;
    z-index: 1;
    display: flex;
    justify-content: space-around;
    width: 100%;
    max-width: vw(1440, true);
    padding: 0 vw(20, true);
    margin: vw(60, true) auto 0 auto;
    align-items: center;
    gap: vw(24, true);
    flex-direction: column;
    @include media-breakpoint-up(md) {
      max-width: vw(1440);
      padding: 0 vw(20);
      margin: 0 auto;
      gap: vw(24);
      flex-direction: row;
    }
  }
  &__header-content {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-up(md) {
      max-width: 50%;
    }
  }
  &__header-title {
    width: 100%;
    max-width: vw(220, true);
    max-height: vw(195, true);
    filter: drop-shadow(0 0 2px map-get($c_list, 'black'));
    animation: titleZoomIn 0.5s ease-in forwards;
    @include media-breakpoint-up(md) {
      max-width: vw(330);
      max-height: none;
    }
  }
  &__header-emoji {
    width: 100%;
    align-self: flex-end;
    max-width: vw(64, true);
    @include media-breakpoint-up(md) {
      max-width: vw(90);
    }
  }
  &__header-coin {
    margin-top: vw(-80, true);
    margin-left: vw(-28, true);
    @include media-breakpoint-up(md) {
      margin: 0;
      width: vw(87);
    }
    align-self: flex-start;
    width: vw(87, true);
    max-width: 20%;
  }
  &__content {
    color: map-get($map: $c_list, $key: 'white');
    text-align: center;
    animation: fadeIn 2s linear 1s forwards;
    opacity: 0;
    @include media-breakpoint-up(md) {
      text-align: left;
    }
    span {
      color: map-get($map: $c_list, $key: 'warning');
    }
  }
  &__content-title {
    font-family: $f_main;
    margin: 0;
    @include text(34, 64);
    @include media-breakpoint-up(md) {
      @include text(48, 89);
    }
  }
  &__content-description {
    @include text(18, 24);
    max-width: vw(360, true);
    font-family: $f_inter;
    font-weight: map-get($map: $f_weights, $key: 'medium');
    margin-bottom: vw(46, true);
    @include media-breakpoint-up(md) {
      max-width: vw(380);
      margin-bottom: vw(46);
      @include text(25, 33);
    }
    span,
    strong {
      font-family: $f_main;
      font-weight: map-get($map: $f_weights, $key: 'extra-bold');
    }
    span {
      @include text(24, 28);
      text-shadow: 0 0 2px black;
      @include media-breakpoint-up(md) {
        @include text(32, 38);
      }
    }
  }
  &__content-coin {
    margin: vw(20, true) 0 0 auto;
    display: block;
    @include media-breakpoint-up(md) {
      margin: vw(20) 0 0 auto;
    }
  }
}

/* Animation Keyframes*/
@keyframes titleZoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  80% {
    opacity: 1;
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes smoke1 {
  0% {
    right: 0;
    bottom: 0;
    transform: scale(1);
  }
  44% {
    right: -4%;
    bottom: -2%;
    transform: scale(1.05);
  }
  72% {
    transform: scale(0.95);
    right: -5%;
    bottom: -2%;
  }
  100% {
    right: 0;
    bottom: 0;
  }
}

@keyframes smoke2 {
  0% {
    left: 0;
    top: 0;
    transform: scale(1);
  }
  33% {
    left: -5%;
    top: -2%;
    transform: scale(1.05);
  }
  54% {
    left: -4%;
    top: 0%;
    transform: scale(1.15);
  }
  100% {
    transform: scale(1);
    left: 0;
    top: 0;
  }
}
