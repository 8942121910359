@use 'styles/configs/' as *;

.deepAR-mirror {
  width: 100% !important;
  opacity: 0;
  animation: fadeIn 2s linear forwards;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
