@use 'styles/configs/' as *;

.screen-quizz {
  height: 100%;
  overflow: auto;
  &::after {
    content: "";
    width: 100%;
    height: vw(90);
    font-size: vw(50);
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(180deg, rgba(29,16,41,0) 0%, rgba(29,16,41,0.844958051580007) 15%, rgba(23,14,33,1) 100%);

    @include media-breakpoint-down(md) {
      height: vw(90, true);
      font-size: vw(50, true);
    }
  }
}
