@use 'styles/configs/' as *;

.activity-fridge-locker {
    position: absolute;
    top: 40%;
    transform: translate(0, -30%);
    right: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 38%;
    @include media-breakpoint-up(md) {
        top: 50%;
        width: 40%;
    }
    &.--is-open {
        pointer-events: none;
    }
    &__lock-top {
        width: 80%;
        position: relative;
    }
    &__lock-bottom {
        width: 100%;
        transform: translate(0, -31%);
        position: relative;
        transition: all 0.25s ease-in;
        .--is-open & {
            transform: translate(0, -15%);
        }
    }
    &__button-wrapper {
        position: absolute;
        width: calc(61% + #{vw(2, true)});
        height: calc(18% - #{vw(2, true)});
        bottom: calc(38% - #{vw(2, true)});
        left: 20%;
        display: flex;
        justify-content: space-around;
        gap: 6%;
        align-items: center;
        transition: all 0.25s ease-in;

        @include media-breakpoint-up(md) {
            width: calc(61% + #{vw(2)});
            height: calc(18% - #{vw(2)});
            bottom: calc(38% - #{vw(2)});
        }
        .--is-open & {
            bottom: 29.5%;
        }
    }
    .bomb-timer {
        transform: translate(-50%, -50%);
        position: absolute;
        left: 50%;
        top: -40%;
    }
}
