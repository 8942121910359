@use 'styles/configs/' as *;

.vape-piece-information {
  box-sizing: border-box;
  position: absolute;
  bottom: vw(90, true);
  left: vw(20, true);
  margin: 0 auto;
  background-color: map-get($map: $c_list, $key: "white");
  width: calc(100% - #{vw(40, true)});
  line-height: 1;
  //box-shadow: 0 0 10px 0 rgba($color: map-get($c_list, "warning"), $alpha: 0.7);
  //border: 1px solid map-get($c_list, "warning");
  padding: 24px;
  opacity: 1;
  visibility: visible;
  transition: opacity 0.25s ease-in 0.25s;
  height: auto;
  border-radius: 12px;

  @include media-breakpoint-up(md) {
    animation: none;
    width: auto;
    right: vw(20);
    transform: none;
    max-width: 320px;
    bottom: vw(120);
  }

  @include media-breakpoint-up(xl) {
    max-width: vw(360);
    right: 0;
  }

  &:empty {
    transition: opacity 0.25s ease-in 0s;
    opacity: 0;
  }

  p{
    margin: 0;
  }
}
