@use 'styles/configs/' as *;

/* if mobile device */
.is-mobile-device {
  #canvas {
    scale: 1.25;
    transform: scaleX(-1) translateX(41%);
  }
}

#cinema {
  margin: auto;
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
}

#outer-container {
  display: flex;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

#inner-container {
  margin: auto;
  position: relative;
  align-self: center;
  width: 100%;
  height: 100%;
}

canvas {
  height: 100% !important;
  width: unset !important;

  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

#canvas {
  position: absolute;
  transform: scaleX(-1) translateX(50%);
  z-index: -1;
  display: none;
  filter: grayscale(0.25);
}

.video {
  transform: scale(0);
  position: absolute;
}
