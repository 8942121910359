@use 'styles/configs/vars' as *;

@keyframes slide-down {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
@keyframes slide-up {
  0% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
  100% {
    opacity: 0;
    -webkit-transform: translateY(-100%);
  }
}

@keyframes pulse {
  0% {
    opacity: 0.7;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.7;
  }
}

@keyframes pull-right {
  0% {
    transform: translate(0.25rem, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(0.25rem, 0);
  }
}

@keyframes pull-left {
  0% {
    transform: translate(-0.25rem, 0);
  }
  50% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-0.25rem, 0);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
