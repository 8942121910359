@use 'styles/configs/' as *;

.activity-button {
  $this: &;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  border-radius: 0;

  &.--is-active {
    #{$this}__image {
      animation: haloFadeActive 2500ms infinite;
    }
  }

  &.disabled {
    cursor: initial;
    #{$this}__image {
      filter: none;
      animation: none;
    }
  }

  &__image {
    width: 100%;
    height: auto;
    display: block;
    pointer-events: none;
    animation: haloFade 2500ms infinite;
  }

  &__btn {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
  }
}


@keyframes haloFade {
  0% {
    filter: drop-shadow(0 0 7px map-get($map: $c_list, $key: 'primary'));
  }

  50% {
    filter: drop-shadow(0 0 4px map-get($map: $c_list, $key: 'primary'));
  }

  100% {
    filter: drop-shadow(0 0 7px map-get($map: $c_list, $key: 'primary'));
  } 
}

@keyframes haloFadeActive {
  0% {
    filter: drop-shadow(0 0 12px map-get($map: $c_list, $key: 'primary'));
  }

  50% {
    filter: drop-shadow(0 0 7px map-get($map: $c_list, $key: 'primary'));
  }

  100% {
    filter: drop-shadow(0 0 12px map-get($map: $c_list, $key: 'primary'));
  } 
}