@use 'styles/configs/' as *;

.cocktail-list {
    position: absolute;
    top: 80px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    max-width: 480px;
    @include media-breakpoint-up(md) {
        left: calc(50% - #{vw(290)});
        transform: none;
        top: vw(80);
        width: auto;
        max-width: vw(580);
    }

    &__tablet-wrapper {
        display: grid;
        text-align: center;
        align-items: flex-end;
        grid-template-columns: repeat(4, 1fr);
        gap: 0 16px;
        width: 100%;
        margin: 0 auto;
        @include media-breakpoint-up(md) {
            width: vw(464);
            gap: 0 vw(16)
        }
    }

    &__tablet {
        grid-column: span 4;
        width: 100%;
        margin-top: -20px;
        margin-bottom: 20px;
        @include media-breakpoint-up(md) {
            width: 140%;
            margin-left: -20%;
            margin-top: vw(-20);
            margin-bottom: vw(20);
        }
    }

    &__ingredient {
        position: relative;
        z-index: 1;
        cursor: pointer;
    }

    &__ingredient-icon {
        pointer-events: none;
    }
}
