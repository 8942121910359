@use 'styles/configs/' as *;

.activity-splash-screen {
  height: 100%;
  &__container {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    max-width: vw(1440, true);
    margin: 0 auto;
    padding-bottom: vw(80, true);
    @include media-breakpoint-up(md) {
      max-width: vw(1440);
      height: 100%;
      flex-direction: row;
      gap: vw(48);
      align-items: center;
      padding-bottom: vw(80);
    }
  }
  &__image {
    width: 100%;
    margin-top: vw(80, true);
    animation: fadeIn 5s;
    max-height: vw(80, true);
    max-width: vw(540, true);
    @include media-breakpoint-up(md) {
      max-width: vw(540);
      max-height: none;
      margin-top: vw(-48);
    }
    @include media-breakpoint-up(lg) {
      margin-top: vw(-120);
    }
  }
  &__content {
    padding: vw(20, true);
    text-align: left;

    @include media-breakpoint-up(md) {
      padding: vw(20);
    }

    &-title {
      color: map-get($map: $c_list, $key: 'white');
      @include text(26, 32);
      font-family: $f_secondary;
      font-weight: map-get($map: $f_weights, $key: 'regular');
      margin-bottom: vw(16, true);
      margin-top: vw(12, true);
      @include media-breakpoint-up(md) {
        @include text(40, 44);
        margin-bottom: vw(24);
        margin-top: vw(12);
      }
    }
    &-description {
      @include text(16, 19);
      color: map-get($map: $c_list, $key: 'white');
      font-family: $f_inter;
      font-weight: map-get($map: $f_weights, $key: 'medium');
      @include media-breakpoint-up(sm) {
        @include text(18, 23);
      }
      @include media-breakpoint-up(md) {
        @include text(20, 26);
      }
    }
  }

  &__actions {
    z-index: 1;
    position: absolute;
    bottom: vw(25);
    left: vw(20);
    right: vw(20);

    display: flex;
    flex-direction: column;
    gap: vw(8);

    @include media-breakpoint-down(md) {
      bottom: vw(25, true);
      left: vw(20, true);
      right: vw(20, true);
      gap: vw(8, true);
    }

    button {
      width: 100%;
    }

    @include media-breakpoint-up(md) {
      flex-direction: row;
      gap: vw(16);

      right: unset;
      left: 50%;
      transform: translate(-50%, 0);

      @include media-breakpoint-down(md) {
        gap: vw(12, true);
      }

      button {
        width: unset;
      }
    }
  }
}
