@use 'styles/configs/' as *;

.header {
    padding-top: vw(18);
    width: 100%;
    max-width: vw(1440);
    margin:0 auto;
    z-index: map-get($map: $zindex, $key: "header");
    display: flex;
    justify-content: center;

    @include media-breakpoint-down(md) {
      padding-top: vw(18, true);
      max-width: vw(1440, true);
    }

    &__logo {
      display: flex;
      align-items: center;
      /* background-color: map-get($map: $c_list, $key: "secondary"); */
      padding: vw(4) vw(4) vw(4) vw(12);
      width: auto;
      cursor: pointer;

      @include media-breakpoint-down(md) {
        padding: vw(4, true) vw(4, true) vw(4, true) vw(12, true);
      }

      @include media-breakpoint-up(lg) {
        padding: vw(12) vw(4) vw(12) vw(12);
      }

      > * {
        cursor: pointer;
        height: vw(54);
        padding: 0 vw(4);

        @include media-breakpoint-down(md) {
          height: vw(54, true);
          padding: 0 vw(4, true);
        }

        @include media-breakpoint-up(lg) {
          height: vw(86);
        }
      }
      & > object {
        pointer-events: none;
      }
      &:hover,
      &:focus {
        .action-bar__exit-button {
          padding-right: vw(2);
          filter: drop-shadow(0 0 vw(2) rgba(map-get($map: $c_list, $key: "white"), 0.4));

          @include media-breakpoint-down(md) {
            padding-right: vw(2, true);
            filter: drop-shadow(0 0 vw(2, true) rgba(map-get($map: $c_list, $key: "white"), 0.4));
          }

          @include media-breakpoint-up(lg) {
            padding-right: vw(8);
          }
        }
      }
    }
    &__exit-button {
      color: #9587b3;
      height: auto;
      filter: drop-shadow(0 0 0 rgba(map-get($map: $c_list, $key: "white"), 0.4));
      padding-right: vw(6);
      font-size: vw(14);
      transition: all 0.25s ease-in;

      @include media-breakpoint-down(md) {
        padding-right: vw(6, true);
        font-size: vw(14, true);
      }

      @include media-breakpoint-up(lg) {
        padding-right: vw(12);
        font-size: vw(20);
      }
    }
    &__music {
      background-color: map-get($map: $c_list, $key: "secondary");
      height: auto;
    }
    &__music-button {
      cursor: pointer;
      display: block;
      background-color: transparent;
      border: none;
      padding: vw(4) vw(12) vw(4) vw(16);
      font-size: vw(24);
      color: map-get($map: $c_list, $key: "warning");
      filter: drop-shadow(0 0 0 rgba(map-get($map: $c_list, $key: "warning"), 0.4));
      transition: all 0.25s ease-in;

      @include media-breakpoint-down(md) {
        font-size: vw(24, true);
        padding: vw(4, true) vw(12, true) vw(4, true) vw(16, true);
      }

      @include media-breakpoint-up(lg) {
        font-size: vw(36);
        padding: vw(10) vw(34) vw(12) vw(24);
      }
      &:hover,
      &:focus {
        padding-left: vw(12);
        filter: drop-shadow(0 0 vw(2) rgba(map-get($map: $c_list, $key: "warning"), 0.4));

        @include media-breakpoint-down(md) {
          padding-left: vw(12, true);
          filter: drop-shadow(0 0 vw(2, true) rgba(map-get($map: $c_list, $key: "warning"), 0.4));
        }

        @include media-breakpoint-up(lg) {
          padding-left: vw(20);
        }
      }
    }
  }
  