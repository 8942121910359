@use 'styles/configs/' as *;

.screen-register {
  position: relative;
  overflow: hidden;
  height: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: $c_background-bottom;
  &::before {
    content: "";
    font-size: vw(50);
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      66deg,
      rgba(73, 74, 118, 1) 0%,
      rgba(73, 74, 118, 1) 35%,
      rgba(52, 45, 94, 1) 35%,
      rgba(52, 45, 94, 1) 87%,
      rgba(29, 22, 51, 1) 87%,
      rgba(29, 22, 51, 1) 100%
    );
    opacity: 0.5;

    @include media-breakpoint-down(md) {
      font-size: vw(50, true);
    }
  }
  &__register-section {
    gap: vw(8, true);
    display: flex;
    flex-direction: column;
    padding: vw(60, true) vw(20, true);
    margin: 0 auto;
    width: 100%;
    height: 100%;
    max-width: vw(1440, true);
    position: relative;
    z-index: 1;
    box-sizing: border-box;
    @include media-breakpoint-up(md) {
      padding: vw(80) vw(20);
      gap: vw(40);
      max-width: vw(1440);
    }
  }
  &__wall-background {
    height: 100%;
  }
  &__doorman {
    position: absolute;
    top: 0;
    height: 200%;
    right: -30%;
    transform: translate(25%, 0);
    @include media-breakpoint-up(md) {
      right: 0;
      transform: translate(-5%, 0);
    }
  }
  &__register-text {
    text-shadow: 0 0 4px map-get($map: $c_list, $key: 'black');
    color: map-get($c_list, 'white');
    max-width: vw(540, true);
    margin: 0 auto;

    @include media-breakpoint-up(md) {
      max-width: 60%;
    }

  }
  &__register-title {
    @include text(30, 32, 'regular', null, 'secondary');
    margin-bottom: vw(14, true);

    @include media-breakpoint-up(md) {
      margin-bottom: vw(14);
    }

    @include media-breakpoint-up(lg) {
      margin-bottom: vw(20);
      @include text(34, 36, 'regular', null, 'secondary');
    }
  }
  &__register-content {    
    @include text(14, 18, 'regular', null, 'inter');
    margin-top: vw(10, true);
    margin-bottom: vw(6, true);

    @include media-breakpoint-up(md) {
      @include text(18, 22, 'regular', null, 'inter');
      margin-top: vw(10);
      margin-bottom: vw(6);
    }

    @include media-breakpoint-up(lg) {
      margin-top: vw(18);
      @include text(20, 24, 'regular', null, 'inter');
    }
    span {
      position: relative;
      &::before {
        left: vw(-4, true);
        top: 0;
        width: calc(100% + #{vw(8, true)});
        height: 100%;
        background-color: #11123b;
        content: "";
        font-size: vw(50, true);
        position: absolute;
        z-index: -1;

        @include media-breakpoint-up(md) {
          left: vw(-4);
          width: calc(100% + #{vw(8)});
          font-size: vw(50);
        }
      }
    }
  }
  &__banner {
    margin: 0 auto;
    background-color: map-get($map: $c_list, $key: "white");
    width: calc(100% - #{vw(40, true)});
    line-height: 1;
    box-shadow: 0 0 10px 0 rgba($color: map-get($c_list, "warning"), $alpha: 0.7);
    border: 1px solid map-get($c_list, "warning");
    transition: opacity 0.25s ease-in;
    padding: 0 vw(12, true);
    opacity: 1;
    visibility: visible;
    animation: fadeOut 5s 10s forwards;

    @include media-breakpoint-up(md) {
      animation: none;
      width: auto;
      transform: none;
      max-width: vw(320);
      padding: 0 vw(12);
    }

    @include media-breakpoint-up(lg) {
      max-width: vw(360);
    }
  }
  button{
    position: absolute;
    bottom: vw(25, true);
    left: vw(20, true);
    right: vw(20, true);
    width: auto;

    @include media-breakpoint-up(md) {
      width: max-content;
      bottom: vw(25);
      right: vw(20);
      left: 50%;
      transform: translateX(-50%);
    }
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
    visibility: visible;
  }

  50% {
    opacity: 0;
    max-height: vw(240);
    visibility: hidden;
  }

  100% {
    max-height: 0;
    opacity: 0;
    visibility: hidden;
  }
  
}