@use 'styles/configs/' as *;

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.navigation-bar {
  animation: fade-in 300ms 300ms forwards;

  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  flex-direction: row;
  gap: 12px;
  z-index: 999;
  opacity: 0;

  background: rgba(40, 43, 77, 0.8);
  border-radius: 30px;
  padding: 5px;

  pointer-events: none;

  @include media-breakpoint-down(md) {
    gap: vw(12, true);
    border-radius: vw(30, true);
    padding: vw(5, true);
  }

  &.pointer-events {
    pointer-events: auto;
  }

  .navigation-station-status-wrapper {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: vw(10);

    &:after {
      content: "";
      display: block;
      width: calc(100% - #{vw(30)});
      height: 1px;
      background: rgba(255, 255, 255, 0.5);

      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      z-index: 1;

      @include media-breakpoint-down(md) {
        width: calc(100% - #{vw(30, true)});
      }
    }

    .navigation-station-status {
      position: relative;
      display: block;
      width: 20px;
      height: 20px;
      border: 1px solid transparent;
      border-radius: 50%;
      background: transparent;
      z-index: 2;

      @include media-breakpoint-down(md) {
        width: 20px;
        height: 20px;
      }

      &.active {
        border-color:  map-get($c_list, "warning");
        background: rgba(40, 43, 77, 1);
      }

      &.done {
        &:before {
          display: block;
        }

        .svg {
          display: block;
        }
      }

      &:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "";
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 1;
        background:  map-get($c_list, "warning");
        display: none;
      }

      &:after {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        content: "";
        width: 10px;
        height: 10px;
        border-radius: 50%;
        display: block;
        background: white;
      }

      .svg {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 1;
        display: none;
      }
    }
  }
}