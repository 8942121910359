@use 'styles/configs/' as *;

.locker-button {
    height: 100%;
    text-align: center;
    position: relative;
    flex-grow: 1;
    border: 2px solid transparent;
    .--is-open & {
        border-color: map-get($map: $c_list, $key: "success");
    }
    .--is-time-running-out & {
        border-color: map-get($map: $c_list, $key: "error");
        animation: timeRunningOut 1s infinite;
    }
    &__arrow {
        position: absolute;
        z-index: 1;
        cursor: pointer;
        background-color: transparent;
        border: none;
        left: 0;
        top: 0;
        width: 100%;
        padding: vw(4, true);
        font-size: vw(18, true);
        color: map-get($map: $c_list, $key: 'black');
        @include media-breakpoint-up(md) {
            padding: vw(4);
            font-size: vw(24);
        }
        @include media-breakpoint-up(lg) {
            font-size: vw(32);
        }
        &-down {
            top: inherit;
            bottom: 0;
        }
    }
    &__current-number {
        padding: 0;
        margin: 0;
        position: absolute;
        top: 50%;
        left: 50%;
        font-family: Helvetica, Arial, sans-serif;
        transform: translate(-50%, -50%);
        @include text(18, 22);
        @include media-breakpoint-up(md) {
            @include text(24, 28);
        }
        @include media-breakpoint-up(lg) {
            @include text(32, 38);
        }
    }
}

@keyframes timeRunningOut {
    0% {
        box-shadow: 0 0 4px 0 map-get($map: $c_list, $key: "error");
    }
    50% {
        box-shadow: 0 0 8px 2px map-get($map: $c_list, $key: "error");
    }
    100% {
        box-shadow: 0 0 4px 0 map-get($map: $c_list, $key: "error");
    }
}
