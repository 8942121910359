@use 'styles/configs/' as *;

.cocktail-drop-zone {
    position: relative;
    z-index: 1;
    cursor: pointer;
    width: 60px;
    height: 84px;
    text-align: center;
    padding: 15px 10px;

    @include media-breakpoint-up(sm) {
        width: vw(80);
        height: vw(84);
        padding: vw(15) vw(10);
    }
    @include media-breakpoint-up(md) {
        width: vw(60);
        height: vw(84);
        padding: vw(15) vw(10);
    }
    &__selection {
        position: absolute;
        bottom: 12px;
        left: 50%;
        transform: translate(-50%, 0);
        max-height: 80px;

        @include media-breakpoint-up(md) {
            max-height: vw(80);
            bottom: vw(12);
        }
    }
    &__icon {
        pointer-events: none;
    }
}
