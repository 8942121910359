@use 'styles/configs/' as *;

.screen-cocktail {
  height: 100%;
  width: 100%;
  max-height: 100vh;
  overflow: hidden;
  position: fixed;
  top: 0;
  left: 0;
}
