@use 'styles/configs/' as *;

.splash-screen {
  position: relative;
  width: 100%;
  height: 100%;
  top: 0 !important;
  left: 0 !important;
  transform: none !important;

  @include media-breakpoint-up(md) {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 0 !important;

    width: 100% !important;
    height: unset;
    top: vw(75) !important;
  }

  &__logo {
    position: relative;
    top: vw(75, true);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    width: vw(210, true);
    margin-top: vw(40, true);
    height: vw(141, true);
    filter: drop-shadow(0 0 2px #{map-get($c_list, 'black')});

    @include media-breakpoint-up(sm) {
      margin-top: 0;
      width: vw(317);
      height: vw(213);

    }

    @include media-breakpoint-up(md) {
      top: unset;
      left: unset;
      transform: none;
    }
  }
  &__logo-svg {
    width: 100%;
    max-height: 100%;
    max-width: vw(225, true);

    @include media-breakpoint-up(md) {
      max-width: vw(225);
    }
  }

  button{
    position: absolute;
    bottom: vw(25, true);
    left: vw(20, true);
    right: vw(20, true);
    width: auto;

    @include media-breakpoint-up(md) {
      position: unset;
      width: max-content;
      bottom: unset;
      left: unset;
      right: unset;
    }
  }
}
