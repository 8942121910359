@use 'styles/configs/' as *;

$c_road: #1b0f26;

.screen-bar {
  width: 100%;
  height: 100%;
  position: relative;

  &__bar-wrapper {
    background: linear-gradient(0deg, rgb(17, 18, 59) 0%, rgb(17, 18, 59) 46%, rgb(23, 14, 33) 46%, #381a4e 99%);
    height: 100%;
    width: vw(3600, false, 0px, 300vw);
    position: absolute;
    bottom: 0;
    left: 0;
    transform-origin: left;

    @include media-breakpoint-down(md) {
      width: vw(2820, true, 0px, 2000vw);
     }
  }

  &__bar {
    width: vw(3600, false, 0px, 300vw);
    position: absolute;
    left: vw(-240, false, -100vw, 200vw);
    bottom: vw(-113, false, -100vw, 200vw);

    @include media-breakpoint-down(md) {
      width: vw(2820, true, 0px, 2000vw);
      left: vw(-240, true, -100vw, 2000vw);
      bottom: vw(-113, true, -100vw, 2000vw);
    }
  }


  &__bar-wrapper-overflow {
    position: relative;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: vw(1440, false, 0px, 200vw);
    height: vw(810, false, 0px, 200vw);

    @include media-breakpoint-down(md) {
      width: vw(375, true, 0px, 2000vw);
      height: vw(667, true, 0px, 2000vw);
    }
  }

  &__quiz {
    z-index: 1;
    position: absolute;
    bottom: vw(449);
    left: vw(2337, false, 0px, 200vw);
    width: vw(121);

    @include media-breakpoint-down(md) {
      bottom: vw(322, true);
      left: vw(1737, true, 0px, 2000vw);
      width: vw(90, true);
    }
  }

  &__mental {
    z-index: 1;
    position: absolute;
    bottom: vw(308);
    left: vw(1763, false, 0px, 200vw);
    width: vw(171);

    @include media-breakpoint-down(md) {
      bottom: vw(217, true);
      left: vw(1320, true, 0px, 2000vw);
      width: vw(127, true);
    }
  }

  &__mirror {
    z-index: 1;
    position: absolute;
    bottom: vw(292);
    left: vw(978, false, 0px, 200vw);
    width: vw(152);

    @include media-breakpoint-down(md) {
      bottom: vw(205, true);
      left: vw(728, true, 0px, 2000vw);
      width: vw(113, true);
    }
  }

  &__defi {
    z-index: 1;
    position: absolute;
    bottom: vw(357);
    left: vw(2507, false, 0px, 200vw);
    width: vw(229);

    @include media-breakpoint-down(md) {
      bottom: vw(253, true);
      left: vw(1851, true, 0px, 2000vw);
      width: vw(170, true);
    }
  }

  &__cocktail {
    z-index: 1;
    position: absolute;
    bottom: vw(206);
    left: vw(217, false, 0px, 200vw);
    width: vw(508);

    @include media-breakpoint-down(md) {
      bottom: vw(141, true);
      left: vw(257, true, 0px, 2000vw);
      width: vw(378, true);
    }
  }

  &__freezer {
    z-index: 1;
    position: absolute;
    bottom: vw(280);
    left: vw(1238, false, 0px, 200vw);
    width: vw(264);

    @include media-breakpoint-down(md) {
      bottom: vw(196, true);
      left: vw(922, true, 0px, 2000vw);
      width: vw(196, true);
    }
  }

  &__puzzle {
    z-index: 1;
    position: absolute;
    bottom: vw(244);
    left: vw(2068, false, 0px, 200vw);
    width: vw(205);

    @include media-breakpoint-down(md) {
      bottom: vw(170, true);
      left: vw(1538, true, 0px, 2000vw);
      width: vw(152, true);
    }
  }

  &__win {
    z-index: 1;
    position: absolute;
    top: 30%;
    right: 7%;
    width: 10%;

    .svg {
      width: 100%;
    }
  }

  &__scroll-anim {
    z-index: 1;
    position: fixed;
    bottom: vw(80);
    left: 50%;
    transform: translate(-50%, 0);
    width: 40%;
    max-width: vw(160);
    pointer-events: none;

    @include media-breakpoint-down(md) {
      bottom: vw(80, true);
      max-width: vw(160, true);
    }

    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}