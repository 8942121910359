@use 'styles/configs/' as *;

.cocktail-result {
    position: relative;
    height: 100%;
    width: 100%;
    background: linear-gradient(180deg, #4b2167 0%, #170e21 100%);

    &__shaking-title {
        box-sizing: border-box;
        background-color: map-get($map: $c_list, $key: 'white');
        @include text(18, 22, 'medium', null, 'inter');
        transition: opacity 0.25s ease-in;
        padding: 24px;
        border-radius: 12px;

        position: absolute;
        top: vw(80, true);
        left: vw(20, true);
        width: calc(100% - #{vw(40, true)});
        //@include text(20, 18, 'regular', null, 'secondary');
        //color: map-get($map: $c_list, $key: 'white');
        z-index: 1;

        @include media-breakpoint-up(md) {
            top: vw(120);
            left: 50%;
            width: calc(100% - #{vw(40)});
            max-width: vw(680);
            transform: translate(-50%, 0);
            @include text(30, 34, 'regular', null, 'secondary');
        }
    }

    &__barman {
        width: 200%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
        @include media-breakpoint-up(md) {
            max-height: 60vh;
            width: 140%;
        }
        @include media-breakpoint-up(lg) {
            width: 100%;
        }
    }

    &__display {
        box-sizing: border-box;
        color: map-get($map: $c_list, $key: 'white');
        width: 100%;
        height: calc(100% - 135px);
        display: flex;
        align-items: center;
        margin: 0 auto;
        padding-top: vw(50, true);
        gap: vw(20, true);
        flex-direction: column;
        //background: linear-gradient(180deg, #4b2167 0%, #170e21 100%);
        overflow: scroll;

        @include media-breakpoint-up(md) {
            padding-top: vw(50);
            gap: vw(20);
        }
    }

    &__cocktail-heading {
        margin-top: vw(60, true);
        display: flex;
        align-items: center;
        flex-direction: column;
        @include text(14, 20, 'medium', null, 'inter');
        gap: vw(8, true);
        width: calc(100% - #{vw(40, true)});
        max-width: vw(480, true);
        @include media-breakpoint-up(md) {
            margin-top: vw(90);
            gap: vw(8);
            width: calc(100% - #{vw(40)});
            max-width: vw(480);
            @include text(22, 24, 'medium', null, 'inter');
        }
    }

    &__cocktail {
        max-width: vw(1440, true);
        padding: 0 vw(20, true);
        align-items: center;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        @include media-breakpoint-up(md) {
            max-width: vw(1440);
            padding: 0 vw(20);
            grid-template-columns: repeat(2, 1fr);
        }
    }

    &__cocktail-recommandation {
        margin: 0;
    }

    &__cocktail-name {
        width: 100%;
        max-width: vw(320, true);
        @include media-breakpoint-up(md) {
            margin-bottom: vw(40);
            max-width: vw(460);
        }
    }

    &__cocktail-image {
        display: block;
        max-width: vw(320, true);
        max-height: 15vh;
        margin: 0 auto;
        width: 100%;
        height: auto;
        @include media-breakpoint-up(md) {
            max-height: none;
            max-width: vw(320);
        }
    }

    &__cocktail-information {
        position: relative;
        @include text(16, 18, 'medium', null, 'inter');
        @include media-breakpoint-up(sm) {
            @include text(18, 22, 'medium', null, 'inter');
        }
        @include media-breakpoint-up(md) {
            @include text(20, 24, 'medium', null, 'inter');
            max-width: vw(480);
        }

        ul {
            margin-top: vw(24, true);
            @include text(16, 20, 'medium', null, 'inter');
            list-style: none;
            @include media-breakpoint-up(md) {
                margin-top: vw(24);
                @include text(18, 20, 'medium', null, 'inter');
            }
        }

        li {
            margin-bottom: vw(10, true);
            @include media-breakpoint-up(md) {
                margin-bottom: vw(16);
            }
        }
    }

    &__actions {
        z-index: 1;
        position: absolute;
        bottom: vw(25, true);
        left: vw(20, true);
        right: vw(20, true);

        display: flex;
        flex-direction: column;
        gap: vw(8, true);

        &:before {
            display: block;
            content: '';

            position: absolute;
            bottom: calc(100% + 2px);
            left: -20px;
            right: -20px;

            height: 50px;
            background: linear-gradient(0deg, #21112f, transparent 100%);
        }

        button {
            width: 100%;
        }

        @include media-breakpoint-up(md) {
            flex-direction: row;
            gap: vw(16);
            bottom: vw(25);
            right: unset;
            left: 50%;
            transform: translate(-50%, 0);

            button {
                width: unset;
            }
        }
    }
}
